import React, { useEffect, useRef } from 'react';

import Container from '@components/Container';
import Card from '@components/Card/Card';
import colors from '../../styles/colors';
import Illustration from '@components/Illustration/Illustration';
import TextLink from '@components/TextLink/TextLink';
import HorizontalScroll from '@components/HorizontalScroll';

import Icon, { IconName } from '@components/Icon/Icon';
import PrimaryButton from '@components/PrimaryButton/PrimaryButton';

import * as styles from './CardPackageInfo.module.scss';
import useAppPackages from '@src/hooks/useAppPackages.hook';

const data = [
  {
    name: 'Standard',
    features: [
      {
        icon: 'CreditCard',
        name: 'Virtuellt kort',
      },
      {
        icon: 'Bolt',
        name: 'Upp till 1,00 % för rörligt sparkonto',
        floatingInterest: true,
      },
      {
        icon: 'Vault',
        name: 'Upp till 3,25 % för sparvalv',
        fixedInterest: true,
      },
    ],
    cta: {
      title: 'Kostnadsfritt',
      href: '/paket/standard',
      label: 'Mer info om standardpaketet',
    },
  },
  {
    name: 'Plus',
    features: [
      {
        icon: 'CreditCard',
        name: 'Fysiskt kort',
      },
      {
        icon: 'Bolt',
        name: 'Upp till 1,75 % för rörligt sparkonto',
        floatingInterest: true,
      },
      {
        icon: 'Vault',
        name: 'Upp till 3,25 % för sparvalv',
        fixedInterest: true,
      },
      {
        icon: 'Laugh',
        name: 'Upp till 5% cashback',
      },
      {
        icon: 'Calendar',
        name: 'Ingen uppläggningsavgift för lån',
      },
      {
        icon: 'ArrowRight',
        name: 'Kostnadsfri kredit på 3 000 kr ingår',
      },
      {
        icon: 'Dollar',
        name: '1 månad gratis',
      },
    ],
    cta: {
      title: '29kr/månad',
      href: '/paket/plus',
      label: 'Mer info om pluspaketet',
    },
  },
  {
    name: 'Premium',
    features: [
      {
        icon: 'Fingerprint',
        name: 'Biometriskt kort',
      },
      {
        icon: 'Bolt',
        name: 'Upp till 2,50 % för rörligt sparkonto',
        floatingInterest: true,
      },
      {
        icon: 'Vault',
        name: 'Upp till 3,25 % för sparvalv',
        fixedInterest: true,
      },
      {
        icon: 'Laugh',
        name: 'Upp till 10% cashback',
      },
      {
        icon: 'Calendar',
        name: 'Ingen uppläggningsavgift för lån',
      },
      {
        icon: 'ArrowRight',
        name: 'Kostnadsfri kredit på 5 000 kr ingår',
      },
      {
        icon: 'Dollar',
        name: '6 månader gratis för nya kunder i juni-september (värde 594 kr)',
      },
    ],
    cta: {
      title: '99kr/månad',
      href: '/paket/premium',
      label: 'Mer info om premiumpaketet',
    },
  },
];

type CardPackageInfoProps = React.HTMLProps<HTMLDivElement> & {
  hideCompareLink?: boolean;
};

function CardPackageInfo({ hideCompareLink, ...rest }: CardPackageInfoProps) {
  const scrollRef = useRef<HTMLDivElement>();
  const interestRates = useAppPackages();

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = 318;
    }
  }, []);

  return (
    <div className={styles.wrapper} {...rest}>
      <div className={styles.content}>
        <Container
          style={{ paddingTop: 115, paddingBottom: 58, position: 'relative' }}
        >
          <Illustration
            width={103}
            height={123}
            name="Cash1"
            color="green"
            style={{ left: '55%', top: 0 }}
          />
          <Illustration
            width={112}
            height={121}
            name="Percent1"
            className={styles.percent2}
          />
          <h2 style={{ maxWidth: 600, color: colors.purpleSoft }}>
            Välj paketet som passar dig
          </h2>
        </Container>
        <HorizontalScroll ref={scrollRef}>
          {data.map((p, index) => {
            return (
              <PrimaryButton
                className={styles.packageCard}
                asWrapper
                href={p.cta.href}
                aria-label={p.cta.label}
                key={p.name}
              >
                <Card
                  width={336}
                  height={'100%'}
                  style={{ aspectRatio: 'auto' }}
                  small
                  theme="dark"
                >
                  <h3>{p.name}</h3>
                  <ul>
                    {p.features.map(f =>
                      // If the maxInterestProductType is not for savings account with fixed period, then do not render it
                      f.fixedInterest &&
                      interestRates.maxInterestProductType !==
                        'SAVINGS_VAULT' ? null : (
                        <li
                          key={f.name}
                          className={f.icon === 'Dollar' ? styles.yellow : ''}
                        >
                          <Icon
                            name={f.icon as IconName}
                            color="white"
                            width={24}
                            height={24}
                          />
                          {!f.floatingInterest && !f.fixedInterest && f.name}
                          {f.floatingInterest &&
                            `Upp till ${interestRates.list[index].interest} för rörligt sparkonto`}
                          {f.fixedInterest &&
                            `Upp till ${interestRates.maxInterest} för sparvalv`}
                        </li>
                      ),
                    )}
                  </ul>
                  <PrimaryButton>{p.cta.title}</PrimaryButton>
                </Card>
              </PrimaryButton>
            );
          })}
        </HorizontalScroll>
        <div style={{ textAlign: 'center', paddingTop: 35, paddingBottom: 68 }}>
          {!hideCompareLink && (
            <TextLink large to="/paket">
              Jämför alla funktioner
            </TextLink>
          )}
        </div>
        <Container style={{ position: 'relative', zIndex: 1 }}>
          <Illustration
            name="Percent2"
            style={{ left: 0, bottom: -6 }}
            width={134}
            height={81}
          />
        </Container>
      </div>
    </div>
  );
}

export default CardPackageInfo;
