import { numberToPercentageString } from '@src/lib/utils';
import { useEffect, useState } from 'react';

type TierInfo = {
  name: string;
  interest: string;
};

type MaxInterest = string;

type SavingsProductType = 'SAVINGS_VAULT' | 'FLEXIBLE_INTEREST';

type AppPackageInfo = {
  standard: TierInfo;
  plus: TierInfo;
  premium: TierInfo;
  list: TierInfo[];
  interpolate(string): string;
  maxInterest: MaxInterest;
  maxInterestProductType: SavingsProductType;
};

type MaxInterestResponse = {
  serialId: string;
  interestRate: number;
  productType: SavingsProductType;
  durationYear?: number | null;
  durationDays?: number | null;
};

let cached: AppPackageInfo = null;

const useAppPackages = (): AppPackageInfo => {
  const standard = {
    name: 'Rocker Standard',
    interest: '',
  };
  const plus = {
    name: 'Rocker Plus',
    interest: '',
  };
  const premium = {
    name: 'Rocker Premium',
    interest: '',
  };

  const [data, setData] = useState<AppPackageInfo>({
    standard,
    plus,
    premium,
    list: [standard, plus, premium],
    interpolate: str => str,
    maxInterest: '',
    maxInterestProductType: 'SAVINGS_VAULT',
  });

  useEffect(() => {
    if (cached) {
      setData(cached);
      return;
    }

    const createTier = tier => {
      const {
        product: {
          interestRateLadder: { ranges },
          name,
        },
      } = tier;

      const lowestRange = ranges[0];

      return {
        name,
        interest: numberToPercentageString(lowestRange.interestRate),
      };
    };

    const fetchData = async () => {
      const url = 'https://savings.rocker.com/products/tiers';
      const res = await fetch(url);

      const maxInterestUrl =
        'https://savings.rocker.com/products/highest-interest-rate';
      const maxInterestFetch = await fetch(maxInterestUrl);
      const maxInterestRes: MaxInterestResponse = await maxInterestFetch.json();
      const maxInterest = numberToPercentageString(maxInterestRes.interestRate);
      const maxInterestProductType =
        maxInterestRes.productType ?? 'SAVINGS_VAULT';
      const tiers = await res.json();

      const standard = createTier(tiers[0]);
      const plus = createTier(tiers[1]);
      const premium = createTier(tiers[2]);

      const interpolate = (str: string) => {
        str = str.replaceAll('{standardInterest}', standard.interest);
        str = str.replaceAll('{plusInterest}', plus.interest);
        return str.replaceAll('{premiumInterest}', premium.interest);
      };

      const data = {
        standard,
        plus,
        premium,
        list: [standard, plus, premium],
        interpolate,
        maxInterest,
        maxInterestProductType,
      };

      cached = data;

      setData(data);
    };

    fetchData();
  }, []);

  return data;
};

export default useAppPackages;
